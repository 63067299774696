/* [ AUTH PATH ]
  signinPath = '/auth/signin'
  signoutPath = '/auth/signout'
  profilePath = '/auth/profile'
  updateProfilePath = '/auth/update-profile'
  changepassPath = '/auth/change-pass'
  deleteUserPath = '/auth/delete-user'
*/

const HEADER_JSON = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}
type AuthEventName = 'profile' | 'signin' | 'signout' | 'presignout' | 'passwordchange' | 'error'
type AuthEventHandler = (e?: {
  accessToken?: string
  credential?: string
  domains: any[]
  domain: any
  languages?: { code: string; display: string }[]
}) => void
type AuthErrorHandler = (err: any) => void

class ClientAuth {
  private listeners: {
    profile: AuthEventHandler[]
    signout: AuthEventHandler[]
    signin: AuthEventHandler[]
    presignout: AuthEventHandler[]
    passwordchange: AuthEventHandler[]
    error: AuthErrorHandler[]
  } = {
    profile: [],
    signout: [],
    signin: [],
    presignout: [],
    passwordchange: [],
    error: []
  }

  private authRequiredEventListener = this.onAuthRequired.bind(this)
  private activateRequiredEventListener = this.onActivateRequired.bind(this)

  private _credential: any
  private accessToken?: string
  private domains: any[] = []
  private domain: any
  private languages: { code: string; display: string }[] = []

  constructor() {
    document.addEventListener('auth-required', this.authRequiredEventListener)
    document.addEventListener('activate-required', this.activateRequiredEventListener)
  }

  on(event: AuthEventName, handler: AuthEventHandler | AuthErrorHandler) {
    var listeners = this.listeners[event]
    if (listeners) {
      listeners.push(handler)
    } else {
      console.log('unknown event', event)
    }

    if (event == 'profile' && this._credential) {
      /* 
        특별히 event 가 profile 인 경우에는 이미 fetch된 credential이 있다면, 콜백을 해준다. 
        시스템 bootstrap에서 profile 이벤트가 사용되는 경우가 많은데, profile도 매우 초기에 fetch 되므로 레이스컨디션이 발생할 수 있기 때문에, 확실하게 event 콜백을 보장하기 위해서이다.
      */
      handler({ credential: this._credential, domains: this.domains, domain: this.domain, languages: this.languages })
    }
  }

  off(event: AuthEventName, handler: AuthEventHandler | AuthErrorHandler) {
    var listeners = this.listeners[event]
    if (listeners) {
      let idx = listeners.indexOf(handler)
      idx >= 0 && listeners.splice(idx, 1)
    } else {
      console.log('unknown event', event)
    }
  }

  dispose() {
    document.removeEventListener('auth-required', this.authRequiredEventListener)
    document.removeEventListener('activate-required', this.activateRequiredEventListener)

    this.listeners = {
      profile: [],
      signin: [],
      signout: [],
      presignout: [],
      passwordchange: [],
      error: []
    }
  }

  private onProfileFetched({ credential, accessToken, domains, domain, languages }) {
    this._credential = credential
    this.domains = domains
    this.domain = domain
    this.languages = languages

    if (accessToken && !this.accessToken) {
      /*
      기존에 세션을 가지거나, 액세스토큰으로 인증된 경우,
      이 경우는 signin 이벤트리스너들을 호출해서 authenticated 상태로 되도록 유도한다.
      */
      this.accessToken = accessToken
      this.listeners.signin.forEach(handler => handler({ accessToken, domains, domain, languages }))
    }
    accessToken && (this.accessToken = accessToken)
    this.listeners.profile.forEach(handler => handler({ credential, domains, domain, languages }))
  }

  private async onPreSignout() {
    for (let onpresignout of this.listeners.presignout) {
      await onpresignout()
    }
  }

  private onAuthError(error) {
    /* signin, signup 과정에서 에러가 발생한 경우 */
    this.listeners?.error.forEach(handler => handler(error))
  }

  private onPasswordChanged(result) {
    //event is passwordchange, handler is result
    this.listeners?.passwordchange.forEach(handler => handler(result))
  }

  private onAuthRequired(e) {
    console.warn('authentication required')
    let url = new URL(window.location.href)
    url.pathname = '/auth/signin'
    url.searchParams.append('redirect_to', window.location.href)

    window.location.href = url.href
  }

  private onActivateRequired(e) {
    console.warn('activate required')
    var params = new URLSearchParams()
    params.append('email', e.email)

    window.location.replace(`/auth/activate?${params}`)
  }

  get credential() {
    return this._credential
  }

  route(path, redirected) {
    /* history에 남긴다. redirected된 상태임을 남긴다. */
    const location = window.location
    const origin = location.origin || location.protocol + '//' + location.host
    const href = `${origin}${path}`

    if (location.pathname === path) return

    // popstate 이벤트가 history.back() 에서만 발생하므로
    // 히스토리에 두번을 넣고 back()을 호출하는 편법을 사용함.
    // forward history가 한번 남는 문제가 있으나 signin 프로세스 중에만 발생하므로 큰 문제는 아님.
    // 이 로직은 login process가 어플리케이션 구조에 종속되는 것을 최소화하기 위함임.
    // 예를 들면, redux 구조에 들어가지 않아도 로그인 프로세스가 동작하도록 한 것임.
    window.history.pushState({ redirected }, '', href)
    window.history.pushState({}, '', href)

    window.history.back()
  }

  async updateProfile(formProps) {
    const response = await fetch('/auth/update-profile', {
      method: 'POST',
      credentials: 'include',
      headers: HEADER_JSON,
      body: JSON.stringify(formProps)
    })

    const message = await response.text()
    if (response.ok) {
      return message
    }

    throw new Error(message)
  }

  async changePassword(formProps) {
    try {
      const response = await fetch('/auth/change-pass', {
        method: 'POST',
        credentials: 'include',
        headers: HEADER_JSON,
        body: JSON.stringify(formProps)
      })

      const message = await response.text()
      if (response.ok) {
        this.onPasswordChanged({ message })
      } else {
        this.onAuthError({ message })
      }
    } catch (e) {
      this.onAuthError(e)
    }
  }

  async deleteUser(params) {
    const response = await fetch('/auth/delete-user', {
      method: 'POST',
      credentials: 'include',
      headers: HEADER_JSON,
      body: JSON.stringify(params)
    })

    const message = await response.text()
    if (response.ok) {
      return message
    } else {
      throw new Error(message)
    }
  }

  async profile() {
    try {
      var searchParams = new URLSearchParams(location.search)
      var token = searchParams.get('token')
      var headers = JSON.parse(JSON.stringify(HEADER_JSON))

      if (token) {
        headers.authorization = `Bearer ${token}`
      }

      const response = await fetch('/auth/profile', {
        method: 'GET',
        credentials: 'include',
        headers
      })

      if (response.ok) {
        if (response.redirected) {
          location.href = response.url
          return
        }

        const data = await response.json()

        this.onProfileFetched({
          credential: data.user,
          accessToken: data.token,
          domains: data.domains,
          domain: data.domain,
          languages: data.languages
        })

        return
      }
    } catch (e) {
      this.onAuthError(e)
    }
  }

  async signout() {
    await this.onPreSignout()

    window.location.href = '/auth/signout'
  }
}

export const auth = new ClientAuth()
