import { DataGridField } from '../data-grid/data-grid-field'
import { ColumnConfig, FieldEditor, GristRecord } from '../types'
import { OxGristEditor } from './ox-grist-editor'
import { OxGristEditorCheckbox } from './ox-grist-editor-checkbox'
import { OxGristEditorColor } from './ox-grist-editor-color'
import { OxGristEditorDate } from './ox-grist-editor-date'
import { OxGristEditorDateTime } from './ox-grist-editor-datetime'
import { OxGristEditorEmail } from './ox-grist-editor-email'
import { OxGristEditorFile } from './ox-grist-editor-file'
import { OxGristEditorImage } from './ox-grist-editor-image'
import { OxGristEditorMonth } from './ox-grist-editor-month'
import { OxGristEditorMultipleSelect } from './ox-grist-editor-multiple-select'
import { OxGristEditorNumber } from './ox-grist-editor-number'
import { OxGristEditorPassword } from './ox-grist-editor-password'
import { OxGristEditorSelect } from './ox-grist-editor-select'
import { OxGristEditorTel } from './ox-grist-editor-tel'
import { OxGristEditorText } from './ox-grist-editor-text'
import { OxGristEditorTextarea } from './ox-grist-editor-textarea'
import { OxGristEditorTime } from './ox-grist-editor-time'
import { OxGristEditorTree } from './ox-grist-editor-tree'
import { OxGristEditorWeek } from './ox-grist-editor-week'
import { OxGristEditorVarname } from './ox-grist-editor-varname'

var EDITORS: { [name: string]: { new (): OxGristEditor } } = {
  string: OxGristEditorText,
  text: OxGristEditorText,
  textarea: OxGristEditorTextarea,
  email: OxGristEditorEmail,
  tel: OxGristEditorTel,
  password: OxGristEditorPassword,
  integer: OxGristEditorNumber,
  float: OxGristEditorNumber,
  number: OxGristEditorNumber,
  select: OxGristEditorSelect,
  boolean: OxGristEditorCheckbox,
  checkbox: OxGristEditorCheckbox,
  month: OxGristEditorMonth,
  week: OxGristEditorWeek,
  date: OxGristEditorDate,
  time: OxGristEditorTime,
  datetime: OxGristEditorDateTime,
  color: OxGristEditorColor,
  progress: OxGristEditorNumber,
  link: OxGristEditorText,
  image: OxGristEditorImage,
  file: OxGristEditorFile,
  'string[]': OxGristEditorMultipleSelect,
  tree: OxGristEditorTree,
  varname: OxGristEditorVarname
}

export function registerEditor(type: string, editor: { new (): OxGristEditor }) {
  EDITORS[type] = editor
}

export function unregisterEditor(type: string) {
  delete EDITORS[type]
}

export function getEditors(): { [name: string]: { new (): OxGristEditor } } {
  return { ...EDITORS }
}

export function getEditor(type: string): FieldEditor {
  if (typeof type == 'function') {
    return type
  }

  return function (value: any, column: ColumnConfig, record: GristRecord, rowIndex: number, field: DataGridField) {
    var clazz = EDITORS[type || 'text'] || OxGristEditorText

    var element = new clazz()

    element.value = value
    element.record = record
    element.column = column
    element.row = rowIndex
    element.field = field

    return element
  }
}
