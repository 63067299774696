import './data-grid/data-grid'
import './data-list/data-list'
import './data-card/data-card'

import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property, query, queryAsync, state } from 'lit/decorators.js'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'

import Headroom from '@operato/headroom'
import { pulltorefresh } from '@operato/pull-to-refresh'
import { HeadroomStyles, ScrollbarStyles, SpinnerStyles } from '@operato/styles'
import { PagePreferenceProvider } from '@operato/p13n'
import { SnapshotTaker, TimeCapsule } from '@operato/utils'

import { buildConfig } from './configure/config-builder'
import { ZERO_CONFIG, ZERO_DATA, ZERO_PAGES, ZERO_PAGINATION } from './configure/zero-config'
import { DataCard } from './data-card/data-card'
import { DataConsumer } from './data-consumer'
import { DataGrid } from './data-grid/data-grid'
import { DataList } from './data-list/data-list'
import { DataProvider } from './data-provider'
import {
  ColumnConfig,
  FetchHandler,
  FilterConfigObject,
  FilterValue,
  GristConfig,
  GristData,
  GristRecord,
  GristSelectFunction,
  PaginationConfig,
  PersonalGristPreference,
  SortersConfig
} from './types'
import { convertListParamToSearchString, convertSearchStringToListParam } from './utils'

/**
 * A custom element for rendering data in a grid, list, or card format.
 *
 * @element ox-grist
 */
@customElement('ox-grist')
export class DataGrist extends LitElement implements DataConsumer {
  static styles = [
    ScrollbarStyles,
    HeadroomStyles,
    SpinnerStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        background-color: var(--grist-background-color);
        min-height: 120px;

        overflow: hidden;

        /* for pulltorefresh controller */
        position: relative;

        padding: var(--ox-grist-padding);

        --md-icon-size: var(--grid-record-wide-fontsize);
      }

      #wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
      }

      ox-grid {
        flex: 1;
        border: var(--grid-wrap-container-border, 0px solid transparent);
        border-width: var(--grid-wrap-container-border-width, 0px);
      }

      slot[name='headroom'] {
        display: block;
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        box-sizing: border-box;
        background-color: var(--grist-background-color);

        z-index: 8;
      }
    `
  ]

  /**
   * The rendering mode of the component, which can be 'GRID', 'LIST', or 'CARD'.
   * Default is 'GRID'.
   *
   * @property {string}
   */
  @property() mode: 'GRID' | 'LIST' | 'CARD' = 'GRID'

  /**
   * The configuration object for the data grist.
   *
   * @property {Object}
   */
  @property() config: any

  /**
   * The data to be displayed in the data grist.
   *
   * @property {GristData}
   */
  @property({ type: Object }) data: GristData = ZERO_DATA

  /**
   * An array of selected records in the data grist.
   *
   * @property {GristRecord[]}
   */
  @property({ type: Array }) selectedRecords?: GristRecord[]

  /**
   * Indicates whether explicit fetching of data is enabled. If true, data will be fetched
   * only when `fetch` method is called. Default is false.
   *
   * @property {boolean}
   */
  @property({ type: Boolean, attribute: 'explicit-fetch' }) explicitFetch: boolean = false

  /**
   * The fetch handler function used to retrieve data from a remote source.
   *
   * @property {FetchHandler}
   */
  @property() fetchHandler?: FetchHandler

  /**
   * Additional fetch options to be passed to the fetch handler.
   *
   * @property {Object}
   */
  @property() fetchOptions: any

  /**
   * An array of filter values to be applied to the data grist.
   *
   * @property {FilterValue[]}
   */
  @property({ type: Array }) filters?: FilterValue[]

  /**
   * An array of sorters to determine the order of records in the data grist.
   *
   * @property {SortersConfig}
   */
  @property({ type: Array }) sorters?: SortersConfig

  /**
   * The pagination configuration for the data grist.
   *
   * @property {PaginationConfig}
   */
  @property({ type: Object }) pagination?: PaginationConfig

  /**
   * Indicates whether URL parameters are sensitive to changes. If true, changes in URL
   * parameters will trigger data fetching. Default is undefined.
   *
   * @property {boolean}
   */
  @property({ type: Boolean, attribute: 'url-params-sensitive' }) urlParamsSensitive?: boolean

  @property({ type: Object }) personalConfigProvider: PagePreferenceProvider = {
    load: () => {
      return {}
    },
    save: (preference: any) => preference,
    reset: () => {}
  }

  @state() personalConfig?: {
    columns?: Partial<ColumnConfig>[]
    [key: string]: any
  }

  @state() _data: GristData = ZERO_DATA // copy data, dirty data
  @state() _config: GristConfig = ZERO_CONFIG // compiled configuration
  @state() private _showSpinner: boolean = false

  @query('slot[name=headroom]') head!: HTMLElement
  @query('#grist') grist!: DataGrid | DataList | DataCard
  @queryAsync('#wrap') private wrap!: Promise<HTMLElement>

  private timeCapsule?: TimeCapsule
  private snapshotTaker?: SnapshotTaker

  private dataProvider?: DataProvider
  private pulltorefreshHandle?: any
  private headroom?: Headroom
  private orginPaddingTop?: string
  private originMarginTop?: string
  private lastLocation: { origin?: string; pathname?: string; search?: string } = {}

  /* 그리드가 준비되기 전에 fetch 요청이 있었음을 나타내는 플래그임 */
  private pendingFetch?: boolean

  private popstateEventHandler: EventListener = ((e: Event) => {
    const { origin, pathname, search } = window.location
    if (
      this.lastLocation.origin &&
      (this.lastLocation.origin !== origin ||
        this.lastLocation.pathname !== pathname ||
        this.lastLocation.search === search)
    ) {
      return
    }

    this.lastLocation = {
      origin,
      pathname,
      search
    }

    var { filters = [], sorters = [] } = convertSearchStringToListParam(search)

    try {
      if (!isEqual(filters, this.filters) || !isEqual(sorters, this.sorters)) {
        this.dispatchEvent(
          new CustomEvent('fetch-params-change', {
            bubbles: true,
            composed: true,
            detail: {
              filters,
              sorters,
              from: 'url-parameter'
            }
          })
        )
      }
    } catch (e) {
      console.error(`invalid fetch params on URL query string : ${e}`)
    }
  }).bind(this)

  private fetchParamsChangeEventHandler: EventListener = ((e: Event) => {
    const { sorters, filters, from } = (e as CustomEvent).detail

    sorters && (this.sorters = sorters)
    filters && (this.filters = filters)

    if (!this.urlParamsSensitive || from === 'url-parameter') {
      return
    }

    const queryString = convertListParamToSearchString({ filters, sorters, base: window.location.search })
    this.lastLocation.search = queryString ? `?${queryString}` : ''
    const url = `${window.location.pathname}${this.lastLocation.search}`

    history.pushState({}, document.title, url)
  }).bind(this)

  async firstUpdated() {
    // Mutation Observer를 사용하여 슬롯의 크기 변경을 감지하고 다시 그린다.
    const observer = new ResizeObserver(mutationsList => {
      this.setHeadroom()
    })

    observer.observe(this.head)
  }

  async connectedCallback() {
    super.connectedCallback()

    this.dataProvider = new DataProvider(this)

    this.timeCapsule = new TimeCapsule(10)
    this.snapshotTaker = new SnapshotTaker(this, this.timeCapsule!)

    this.addEventListener('fetch-params-change', this.fetchParamsChangeEventHandler)

    await this.updateComplete
  }

  disconnectedCallback() {
    super.disconnectedCallback()

    this.removeEventListener('fetch-params-change', this.fetchParamsChangeEventHandler)

    this.timeCapsule && this.timeCapsule.dispose()
    this.snapshotTaker && this.snapshotTaker.dispose()

    delete this.timeCapsule
    delete this.snapshotTaker

    this.dataProvider?.dispose()
    this.resetPullToRefresh()
  }

  private resetPullToRefresh() {
    if (this.pulltorefreshHandle) {
      this.pulltorefreshHandle()
      delete this.pulltorefreshHandle
    }
  }

  private async setPullToRefresh() {
    this.resetPullToRefresh()
    if (this.mode !== 'GRID' && this.fetchHandler) {
      this.pulltorefreshHandle = pulltorefresh({
        container: await this.wrap,
        scrollable: this.grist.pullToRefreshTarget,
        refresh: () => {
          return this.fetch(true)
        }
      })
    }
  }

  public applyUpdatedConfiguration() {
    if (!this.personalConfig || !this.config) {
      return
    }

    const config = { ...this.config }
    const columns: Partial<ColumnConfig>[] = config.columns

    const { columns: personalColumns, sorters, pagination, mode } = this.personalConfig

    if (personalColumns) {
      const xcolumns = columns.map((column: Partial<ColumnConfig>) => {
        const personalColumn = personalColumns.find(pcolumn => pcolumn.name == column.name)
        return personalColumn ? { ...column, ...personalColumn } : column
      })

      function reorderList(a: { name: string }[], b: { name: string }[]): { name: string }[] {
        // 결과 배열 초기화, a 배열 길이만큼 undefined로 채움
        const result = new Array(a.length)

        // b 배열에 없는 아이템은 원래 위치로 채움
        a.forEach((item, index) => {
          if (!item.name || !b.find(bi => bi.name == item.name)) {
            result[index] = item
          }
        })

        b.forEach(item => {
          const ai = a.find(ai => ai.name == item.name)
          if (ai) {
            result[result.findIndex(slot => slot === undefined)] = ai
          }
        })

        return result
      }

      // 배열 재정렬 실행
      config.columns = reorderList(xcolumns as any, personalColumns as any)
    }

    if (pagination) {
      config.pagination = pagination
    }

    if (sorters) {
      config.sorters = sorters
    }

    if (mode) {
      this.mode = mode
    }

    this._config = buildConfig({
      ...config
    })

    this.dispatchEvent(
      new CustomEvent('config-change', {
        bubbles: true,
        composed: true,
        detail: this.compiledConfig
      })
    )

    this.pagination = this.compiledConfig.pagination || {}

    if (!this.urlParamsSensitive) {
      const sorters = this.compiledConfig?.sorters
      const filters = this.compiledConfig?.columns
        .filter(
          column =>
            column.filter &&
            'value' in (column.filter as FilterConfigObject) &&
            (typeof (column.filter as FilterConfigObject).value === 'boolean' ||
              (column.filter as FilterConfigObject).value)
        )
        .map(column => {
          const filter = column.filter as FilterConfigObject
          return { name: column.name, operator: filter.operator, value: filter.value } as FilterValue
        })

      this.dispatchEvent(
        new CustomEvent('fetch-params-change', {
          bubbles: true,
          composed: true,
          detail: {
            filters,
            sorters,
            from: 'config'
          }
        })
      )
    }

    if (this.dataProvider) {
      const { limit, pages = ZERO_PAGES } = this._config.pagination || ZERO_PAGINATION
      this.dataProvider.page = 1
      this.dataProvider.limit = limit || pages[0] || ZERO_PAGES[0]
    }

    if (this.pendingFetch || (!this.urlParamsSensitive && !this.explicitFetch)) {
      this.pendingFetch = false
      this.fetch()
    }
  }

  private setHeadroom() {
    this.headroom?.destroy()

    if (this.grist && this.head && this.head.style.display !== 'none') {
      const style = getComputedStyle(this.grist)

      this.orginPaddingTop = style.paddingTop || '0'
      this.originMarginTop = style.marginTop || '0'

      this.headroom = new Headroom(this.head, {
        scroller: this.grist,
        onTop: () => {
          this.grist.style.paddingTop = this.orginPaddingTop!
          this.originMarginTop = this.grist.style.marginTop

          this.grist.style.marginTop = this.head.clientHeight + 'px'
        },
        onNotTop: () => {
          this.grist.style.marginTop = this.originMarginTop!
          this.orginPaddingTop = this.grist.style.paddingTop

          this.grist.style.paddingTop = this.head.clientHeight + 'px'
        }
      })

      this.headroom.init()
    } else if (this.orginPaddingTop) {
      this.grist.style.paddingTop = this.orginPaddingTop
      this.grist.style.marginTop = this.originMarginTop!
    }
  }

  render() {
    const empty = !this._showSpinner && this._data.records.length == 0

    return html`
      <slot name="headroom"> </slot>
      <div id="wrap" @keydown=${(e: KeyboardEvent) => this.onKeydown(e)}>
        ${this.mode == 'GRID'
          ? html`
              <ox-grid
                id="grist"
                .config=${this.compiledConfig}
                .data=${this._data}
                .sorters=${this.sorters || []}
                .filters=${this.filters || []}
                .pagination=${this.pagination || {}}
                ?empty=${empty}
              >
                <slot name="setting" slot="setting"> </slot>
              </ox-grid>
            `
          : this.mode == 'CARD'
            ? html`
                <ox-card
                  id="grist"
                  .config=${this.compiledConfig}
                  .data=${this._data}
                  .sorters=${this.sorters || []}
                  .filters=${this.filters || []}
                  ?empty=${empty}
                >
                </ox-card>
              `
            : html`
                <ox-list
                  id="grist"
                  .config=${this.compiledConfig}
                  .data=${this._data}
                  .sorters=${this.sorters || []}
                  .filters=${this.filters || []}
                  ?empty=${empty}
                >
                </ox-list>
              `}
      </div>

      <div id="spinner" ?show=${this._showSpinner}></div>
    `
  }

  /* for timecapsule feature */
  private onKeydown(e: KeyboardEvent) {
    if (e.key === 'z' && (e.metaKey || e.ctrlKey)) {
      if (e.shiftKey) {
        this.redo()
      } else {
        this.undo()
      }
    }
  }

  /**
   * Gets the current state of the component. The state includes information about the
   * dirty records and their changes.
   *
   * @getter
   * @public
   * @type {string}
   */
  get state() {
    return JSON.stringify(this.dirtyData)
  }

  /**
   * Undoes the previous change in the component's data by restoring it to the previous state.
   * This method is part of the TimeCapsule feature, allowing users to revert changes.
   */
  undo() {
    if (!this.timeCapsule?.backwardable) {
      return
    }

    this._data = JSON.parse(this.timeCapsule?.backward())
  }

  /**
   * Redoes the previously undone change in the component's data by restoring it to the next state.
   * This method is part of the TimeCapsule feature, allowing users to reapply changes.
   */
  redo() {
    if (!this.timeCapsule?.forwardable) {
      return
    }

    this._data = JSON.parse(this.timeCapsule?.forward())
  }

  /**
   * Fetches data from a data source and updates the component's data. This method is used to retrieve
   * new data or refresh the existing data in the component.
   *
   * @method
   * @param {boolean} reset - If true, the method resets the scroll position to the top.
   */
  async fetch(reset = true) {
    if (this.compiledConfig === ZERO_CONFIG) {
      /* avoid to be here */
      console.warn('grist is not configured yet.')
      this.pendingFetch = true
      return
    }

    if (reset && this.grist) {
      /*
       * scroll 의 현재위치에 의해서 scroll 이벤트가 발생할 수 있으므로, 이를 방지하기 위해서 스크롤의 위치를 TOP으로 옮긴다.
       * (scroll 이 첫페이지 크기 이상으로 내려가 있는 경우, 첫페이지부터 다시 표시하는 경우에, scroll 이벤트가 발생한다.)
       */
      this.grist.scrollTop = 0
    }

    if (this.dataProvider) {
      let { limit: initLimit, page: initPage, infinite } = this.compiledConfig.pagination || {}
      let { limit = initLimit || ZERO_PAGINATION.limit, page = initPage || ZERO_PAGINATION.page } = this.dataProvider

      if (infinite || this.mode !== 'GRID') {
        await this.dataProvider.attach(reset)
      } else {
        await this.dataProvider.fetch({
          limit,
          page,
          sorters: this.sorters || this.compiledConfig?.sorters,
          sortings: this.sorters || this.compiledConfig?.sorters,
          filters: this.filters
        })

        this.pagination && (this.pagination!.limit = limit)
      }
    }
  }

  async updated(changes: PropertyValues<this>) {
    var needToSetPullToRefresh = false

    if (changes.has('filters')) {
      await this.requestUpdate()
    }

    if (changes.has('sorters')) {
      await this.requestUpdate()
    }

    if (changes.has('personalConfigProvider')) {
      this.personalConfig = await this.personalConfigProvider.load()
    } else if (changes.has('config') || changes.has('personalConfig')) {
      this.applyUpdatedConfiguration()
    }

    if (changes.has('fetchHandler')) {
      this.dataProvider && (this.dataProvider.fetchHandler = this.fetchHandler)
      needToSetPullToRefresh = true
    }

    if (changes.has('fetchOptions')) {
      this.dataProvider && (this.dataProvider.fetchOptions = this.fetchOptions)
    }

    if (changes.has('data')) {
      this.reset()
    }

    if (changes.has('mode')) {
      if (this.mode === 'GRID') {
        this.style.removeProperty('--ox-grist-padding')
      } else {
        this.style.setProperty('--ox-grist-padding', '0')
      }

      needToSetPullToRefresh = true
      this.setHeadroom()
    }

    if (changes.has('selectedRecords')) {
      var { records } = this.data || []
      var selectedRecords = this.selectedRecords || []

      var _records = this.dirtyData.records

      /* 원본데이타에서 index를 찾아서, 복사본 데이타의 selected를 설정한다. */
      selectedRecords.forEach(selected => {
        var index = records.indexOf(selected)
        var record = _records[index]
        if (record) {
          record['__selected__'] = true
        }
      })

      /* update _data property intentionally */
      this.refresh()
    }

    if (changes.has('urlParamsSensitive')) {
      if (this.urlParamsSensitive) {
        //@ts-ignore
        this.popstateEventHandler() // call for the first time

        window.addEventListener('popstate', this.popstateEventHandler)
      } else {
        window.removeEventListener('popstate', this.popstateEventHandler)
      }
    }

    if (needToSetPullToRefresh) {
      await this.setPullToRefresh()
    }
  }

  /**
   * Represents the compiled configuration of the component, which includes various settings and
   * column configurations. You can access this property to get information about how the component
   * is configured.
   *
   * @getter
   * @public
   * @type  {GristConfig}
   */
  get compiledConfig(): GristConfig {
    return this._config
  }

  /**
   * Returns the dirty data in the component, which includes the records that have been added,
   * modified, or deleted but have not been committed to the main data yet.
   *
   * @getter
   * @public
   * @type  {GristData} - An object representing the dirty data.
   */
  get dirtyData(): GristData {
    return (this.grist as any)?.data || {}
  }

  /**
   * Returns an array of GristRecord objects representing the records in the dirty state. These are
   * the records that have been added, modified, or deleted but have not been committed to the main
   * data yet.
   *
   * @getter
   * @public
   * @type  {GristRecord[]} - An array of GristRecord objects representing the dirty records.
   */
  get dirtyRecords() {
    var { records = [] } = this.dirtyData
    return records.filter(record => record['__dirty__'])
  }

  /**
   * Exports a list of patches representing the changes in the dirty state of records. Each patch
   * contains information about whether a record was added, modified, or deleted, along with the
   * record's unique identifier and the changed field values.
   *
   * @param {Object} options - Export options that control the format of the patch list.
   * @param {string} options.flagName - The name of the flag field in the patch indicating the change type (default: 'patchFlag').
   * @param {string} options.addedFlag - The flag value for added records (default: '+').
   * @param {string} options.deletedFlag - The flag value for deleted records (default: '-').
   * @param {string} options.modifiedFlag - The flag value for modified records (default: 'M').
   * @param {string} options.idField - The name of the unique identifier field (default: 'id').
   * @returns {Object[]} - An array of objects representing the patches.
   */
  exportPatchList({ flagName = 'patchFlag', addedFlag = '+', deletedFlag = '-', modifiedFlag = 'M', idField = 'id' }) {
    let dirtyRecords = this.dirtyRecords
    if (!dirtyRecords || dirtyRecords.length == 0) {
      return []
    }

    return dirtyRecords.map(record => {
      let flag = record.__dirty__

      let patch = {
        [flagName]: flag == 'M' ? modifiedFlag : flag == '+' ? addedFlag : deletedFlag
      }

      if (idField in record && record[idField]) {
        patch[idField] = record[idField]
      }

      for (let key in record.__dirtyfields__) {
        patch[key] = record[key]
      }
      return patch
    })
  }

  /**
   * Exports the selected records or all records in the component, depending on the specified options.
   * You can use this method to export data from the component in various formats or for different purposes.
   *
   * @param {Object} options - Export options that control the behavior of the export.
   * @param {boolean} options.ifSelectedOnly - If true, exports only the selected records. If false, exports all records.
   * @param {boolean} options.includeHiddenField - If true, includes hidden fields in the exported data.
   * @returns {Object[]} - An array of objects representing the exported records.
   */
  exportRecords({ ifSelectedOnly = true, includeHiddenField = true } = {}) {
    let records = ifSelectedOnly ? this.selected : this.data.records

    if (ifSelectedOnly && (!records || records.length == 0)) {
      records = this.data.records
    }

    let columns = this.compiledConfig.columns.filter(column => column.type !== 'gutter')
    if (!includeHiddenField) {
      columns = columns.filter(column => !column.hidden)
    }
    let columnNames = columns.map(column => column.name)

    return records.map(item => {
      return columnNames.reduce((record, name) => {
        record[name] = item[name]
        return record
      }, {} as any)
    })
  }

  /**
   * Gets the currently selected records in the component. It returns an array of GristRecord objects
   * that are currently selected. You can access this getter to retrieve the selected records.
   *
   * @getter
   * @public
   * @type {GristRecord[]}
   */
  get selected() {
    var { records = [] } = this.grist?.data
    return records.filter(record => record['__selected__'])
  }

  /**
   * Sets the currently selected records in the component. You can use this setter to programmatically
   * select specific records by providing an array of GristRecord objects to be selected.
   *
   * @setter
   * @public
   * @type {GristRecord[]}
   */
  set selected(selected: GristRecord[]) {
    if (!this.grist) {
      console.warn('grist not ready')
      return
    }

    selected.forEach(record => (record.__selected__ = true))
    this.refresh()
  }

  /**
   * Selects records in the component based on the provided selector function. You can use this method
   * to programmatically select specific records in the component.
   *
   * @method
   * @param {GristSelectFunction} selector - A function that determines which records to select.
   * @param {boolean} reset - If true, clears the previous selection before applying the new one.
   *                         If false, adds to the existing selection.
   */
  select(selector: GristSelectFunction, reset: boolean = false) {
    var { records = [] } = this.grist?.data

    if (reset) {
      this.selected.forEach(record => (record.__selected__ = false))
    }

    records.filter(record => selector(record)).forEach(record => (record.__selected__ = true))
    this.refresh()
  }

  /**
   * Shows the loading spinner in the component's UI to indicate ongoing data loading or processing.
   * You can call this method to display the spinner when necessary.
   */
  showSpinner() {
    this._showSpinner = true
  }

  /**
   * Hides the loading spinner in the component's UI to indicate that data loading or processing has completed.
   * You can call this method to hide the spinner when loading or processing is finished.
   */
  hideSpinner() {
    this._showSpinner = false
  }

  /**
   * Focuses on the component, making it the active element in the document. This method is useful
   * when you want to programmatically set focus to the component.
   */
  focus() {
    super.focus()

    this.grist.focus()
  }

  /**
   * Commits the changes made in the dirty state to the component's data. This method updates the
   * component's data with the changes made in the dirty state and clears the dirty state.
   */
  commit() {
    var { page, total, limit, records } = this.grist.data

    this.data = {
      page,
      total,
      limit,
      records: records.map(record => {
        var copied = {
          ...record
        }

        delete copied.__seq__
        delete copied.__dirty__
        delete copied.__selected__
        delete copied.__changes__
        delete copied.__dirtyfields__
        delete copied.__origin__
        delete copied.__depth__
        delete copied.__expanded__
        delete copied.__check_in_tree__
        delete copied.__children__
        delete copied.__typename

        return copied
      })
    }
  }

  /**
   * Shows the headroom element in the component. The headroom element is typically used for
   * displaying additional information or controls at the top of the component.
   */
  showHeadroom() {
    if (this.head) {
      this.head.style.display = 'block'
      this.setHeadroom()
    }
  }

  /**
   * Hides the headroom element in the component. This method hides the additional information
   * or controls displayed at the top of the component.
   */
  hideHeadroom() {
    if (this.head) {
      this.head.style.display = 'none'
      this.setHeadroom()
    }
  }

  /**
   * Toggles the visibility of the headroom element in the component. If the headroom element is
   * currently visible, this method hides it. If it's hidden, this method shows it.
   */
  toggleHeadroom() {
    if (this.head) {
      const display = this.head.style.display
      this.head.style.display = display !== 'none' ? 'none' : 'block'

      this.setHeadroom()
    }
  }

  /**
   * Forced internal data to be reflected on the screen
   * Data changing through a normal method is automatically reflected on the screen, so it is a method that does not need to be used in general.
   * Therefore, it will be deprecated.
   * @method
   */
  refresh() {
    this.grist.refresh()
  }

  /**
   * Resets the component's data to its original state before any changes were made.
   * This method discards all unsaved changes and restores the data to its initial state.
   *
   * @method
   * @public
   */
  reset() {
    // TODO tree 형태의 데이타로 _data를 만들 때, children, collapsed 등을 감안한다.
    var {
      limit = ZERO_PAGINATION.limit,
      page = ZERO_PAGINATION.page,
      total = ZERO_PAGINATION.total,
      records = []
    } = this.data || ZERO_PAGINATION

    const { childrenProperty, expanded } = this.compiledConfig.tree

    /* 원본 데이타를 남기고, 복사본(_data)을 사용한다. */
    records = ([] as GristRecord[]).concat(
      ...records.map((record, idx) =>
        this.traverseReset(
          record,
          this.mode == 'GRID' ? (page - 1) * limit + idx + 1 : idx + 1,
          0,
          childrenProperty,
          expanded as () => boolean
        )
      )
    )

    if (childrenProperty) {
      records = ([] as GristRecord[]).concat(...records.map(record => this.traverseExpanded(record)))
    }

    this._data = {
      limit,
      page,
      total,
      records
    }

    this.timeCapsule?.reset()
    this.snapshotTaker?.take(true)
  }

  private traverseReset(
    record: GristRecord,
    seq: number,
    __depth__: number,
    childrenProperty: string | undefined,
    expanded: () => boolean
  ): GristRecord {
    const copied = {
      ...record,
      __seq__: seq,
      __origin__: record
    }

    if (childrenProperty) {
      const children: GristRecord[] = record[childrenProperty!]

      const __expanded__ = (expanded as Function)(record)
      const __children__ = (children || []).map(child =>
        this.traverseReset(child, seq, __depth__ + 1, childrenProperty, expanded)
      )

      Object.assign(copied, { __depth__, __children__, __expanded__ })
    }

    return copied
  }

  private traverseExpanded(record: GristRecord): GristRecord[] {
    const { __expanded__, __children__ = [] } = record

    if (__expanded__ && __children__.length > 0) {
      return [record].concat(...__children__.map(child => this.traverseExpanded(child)))
    } else {
      return [record]
    }
  }

  /**
   * Checks for dirty records in the component's data and marks them as dirty.
   * Dirty records are those that have unsaved changes.
   */
  checkDirties() {
    const records = this.dirtyRecords
    const { columns = [] } = this.compiledConfig || {}

    for (var record of records || []) {
      var origin = record['__origin__'] || {}

      var dirtyFields = (record['__dirtyfields__'] = columns
        .filter(column => column.type !== 'gutter' && !isEqual(origin[column.name], record[column.name]))
        .reduce((sum, column) => {
          var name = column.name

          sum[name] = {
            before: origin[name],
            after: record[name]
          }

          return sum
        }, {} as any))

      if (record['__dirty__'] == 'M' && isEmpty(dirtyFields)) {
        delete record['__dirty__']
      }
    }

    this._data = { ...this.dirtyData }

    this.snapshotTaker?.touch()
  }

  /**
   * Clones the selected records in the component's data. It creates a copy of the selected records
   * and marks them as new (added) records.
   */
  cloneSelectedRecords() {
    const records = this.selected || ([] as GristRecord[])

    records.forEach(record => {
      var cloned = {
        __dirty__: '+'
      } as GristRecord

      this.compiledConfig.columns
        .filter(column => column.record.editable)
        .forEach(column => {
          cloned[column.name] = record[column.name]
        })
      const rowIndex = this.dirtyData.records.findIndex(rec => rec === record)

      this.dirtyData.records.splice(rowIndex + 1, 0, cloned)
    })

    this.checkDirties()
  }

  /**
   * Adds child nodes to selected records in the component's tree data. It allows users to add child nodes
   * to the selected parent records.
   */
  addChildNodes() {
    const records = this.selected || ([] as GristRecord[])

    records.forEach(record => {
      this.grist.addChildNode(record)
    })
  }

  /**
   * Adds sibling nodes to selected records in the component's tree data. It allows users to add sibling nodes
   * to the selected records.
   */
  addSiblingNodes() {
    const records = this.selected || ([] as GristRecord[])

    records.forEach(record => {
      this.grist.addSiblingNode(record)
    })
  }

  /**
   * Deletes the selected records in the component's data. It removes the selected records from the data,
   * optionally marking them as deleted.
   *
   * @method
   * @param {boolean} dirty - If true, the method marks the records as deleted.
   */
  deleteSelectedRecords(dirty = true) {
    const records = this.selected || ([] as GristRecord[])

    records.forEach(record => {
      if (dirty) {
        record.__dirty__ = '-'
      }

      const rowIndex = this.dirtyData.records.findIndex(rec => rec === record)
      this.dirtyData.records.splice(rowIndex, 1)
    })

    this.checkDirties()
  }

  /**
   * Adds a new record to the data grid. The added record is marked as newly created
   * by setting the `__dirty__` flag to '+'. This flag indicates that the record
   * is in a "new" state and hasn't been committed to the main data yet.
   *
   * @param {GristRecord} [record] - An optional record to add. If no record is provided,
   * an empty record with the `__dirty__` flag set to '+' will be added.
   */
  addRecord(record?: GristRecord) {
    this._data = {
      ...this._data,
      records: [
        ...this.dirtyData.records,
        {
          ...record,
          __dirty__: '+'
        }
      ]
    }
  }

  /**
   * Retrieves the search text used for filtering records.
   *
   * @property {string}
   */
  get searchText() {
    return (this.filters?.find(filter => filter.operator === 'search')?.value as string)?.match(/^\%(.*)\%$/)?.[1] || ''
  }

  /**
   * Sets the search text for filtering records.
   *
   * @property {string}
   */
  set searchText(searchText: string) {
    var filters = (this.filters || []).filter((filter: FilterValue) => filter.operator !== 'search')

    if (searchText) {
      const filtersConfig = this.compiledConfig.columns.filter(columnConfig => !!columnConfig.filter)
      const searchColumns = filtersConfig.filter(columnConfig => {
        const filter = columnConfig.filter as FilterConfigObject
        return filter!.operator === 'search'
      })

      filters = [
        ...searchColumns.map((column: ColumnConfig) => {
          const { name } = column

          return {
            name,
            operator: 'search',
            value: `%${searchText}%`
          } as FilterValue
        }),
        ...filters
      ]
    }

    this.grist.dispatchEvent(
      new CustomEvent('fetch-params-change', {
        bubbles: true,
        composed: true,
        detail: {
          filters
        }
      })
    )
  }

  getCurrentLimit() {
    return this.dataProvider?.limit || ZERO_PAGINATION.limit
  }
}
