import '@material/web/icon/icon.js'

import { css, html, nothing } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'

import { OxFormField } from './ox-form-field.js'

@customElement('ox-input-signature')
export class OxInputSignature extends OxFormField {
  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: column;
        min-height: var(--signature-min-height, 80px);
        min-width: var(--signature-min-width, 120px);

        background-color: var(--signature-background-color, white);

        overflow: hidden;
      }

      .signature-preview {
        flex: 1;
        align-self: stretch;

        border: 1px solid var(--md-sys-color-outline);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      dialog canvas {
        width: 100%;
        height: 100%;
        border: 1px solid var(--md-sys-color-outline);
      }

      .controls {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        gap: var(--spacing-medium);
      }

      .filler {
        flex: 1;
      }
    `
  ]

  @property({ type: String }) value: string | null = null

  @query('.signature-preview')
  private previewDiv!: HTMLDivElement
  @query('dialog')
  private dialog!: HTMLDialogElement
  @query('canvas')
  private canvas!: HTMLCanvasElement

  private ctx!: CanvasRenderingContext2D
  private isDrawing = false

  render() {
    return html`
      <div class="signature-preview" @click=${this.openDialog}></div>

      <dialog>
        <canvas
          width="800"
          height="400"
          @mousedown=${this.startDrawing}
          @mouseup=${this.stopDrawing}
          @mousemove=${this.draw}
          @mouseleave=${this.stopDrawing}
          @touchstart=${this.startDrawing}
          @touchend=${this.stopDrawing}
          @touchmove=${this.draw}
        ></canvas>
        <div class="controls">
          <button @click="${this.clearCanvas}">Clear</button>
          <div class="filler"></div>
          <button @click="${this.saveSignature}">Save</button>
          <button @click="${this.closeDialog}">Close</button>
        </div>
      </dialog>
    `
  }

  firstUpdated() {
    this.ctx = this.canvas.getContext('2d')!
    this.ctx.strokeStyle = '#000'
    this.ctx.lineWidth = 2

    // 처음 로딩 시 서명 데이터를 미리보기 div에 표시
    if (this.value) {
      this.loadSignature(this.value)
    }
  }

  openDialog() {
    if (this.disabled) return
    this.dialog.showModal()

    // 다이아로그가 열릴 때 현재 value를 캔버스에 그리기
    if (this.value) {
      const img = new Image()
      img.onload = () => {
        this.ctx.drawImage(img, 0, 0, this.canvas.width, this.canvas.height)
      }
      img.src = this.value
    }
  }

  closeDialog() {
    this.dialog.close()
  }

  startDrawing(event: MouseEvent | TouchEvent) {
    if (this.disabled) {
      return
    }

    this.isDrawing = true
    this.ctx.beginPath()
    const position = this.getEventPosition(event)
    this.ctx.moveTo(position.x, position.y)
  }

  stopDrawing() {
    this.isDrawing = false
  }

  draw(event: MouseEvent | TouchEvent) {
    if (!this.isDrawing) return
    const position = this.getEventPosition(event)
    this.ctx.lineTo(position.x, position.y)
    this.ctx.stroke()
  }

  clearCanvas() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
  }

  saveSignature() {
    this.value = this.canvas.toDataURL()
    this._notifyChange()
    this.previewDiv.style.backgroundImage = `url(${this.value})`
    this.closeDialog()
  }

  loadSignature(dataUrl: string) {
    this.previewDiv.style.backgroundImage = `url(${dataUrl})`
  }

  getEventPosition(event: MouseEvent | TouchEvent) {
    const rect = this.canvas.getBoundingClientRect()

    // 캔버스의 실제 크기와 CSS 크기 간의 비율을 계산
    const scaleX = this.canvas.width / rect.width
    const scaleY = this.canvas.height / rect.height

    // 실제 좌표 계산
    const isTouchEvent = event instanceof TouchEvent
    const x = (isTouchEvent ? event.touches[0].clientX - rect.left : (event as MouseEvent).clientX - rect.left) * scaleX
    const y = (isTouchEvent ? event.touches[0].clientY - rect.top : (event as MouseEvent).clientY - rect.top) * scaleY

    return { x, y }
  }

  _notifyChange() {
    this.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        composed: true,
        detail: this.value
      })
    )
  }
}
