import { css, html, LitElement, PropertyValues } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ZERO_COLUMN, ZERO_RECORD } from '../configure/zero-config'
import { DataGridField } from '../data-grid/data-grid-field'
import { ColumnConfig, GristRecord } from '../types'
import { getDefaultValue } from '@operato/time-calculator'

const STYLE = css`
  :host {
    display: flex;

    align-items: center;

    width: 100%;
    height: 100%;

    border: 0;
    background-color: transparent;

    overflow: hidden;
    justify-content: var(--data-grid-field-justify-content, flex-start);
  }

  :host > * {
    display: flex;

    width: 100%;
    height: 100%;

    border: 0;
    background-color: transparent;

    box-sizing: border-box;

    align-items: center;
  }

  :host > style {
    display: none;
  }

  :host > input {
    color: var(--md-sys-color-on-background);
  }

  :host > input[type='checkbox'] {
    width: initial;
    margin: 0;
  }
  *:focus {
    outline: none;
  }

  input[type='file'] {
    opacity: 0%;
  }

  @media screen and (max-width: 460px) {
    :host > * {
      border: initial;
      background-color: initial;
    }

    *:focus {
      outline: none;
    }

    input,
    select,
    textarea {
      font-size: 16px;
    }
  }
`

@customElement('ox-grist-editor')
export class OxGristEditor extends LitElement {
  static styles = [STYLE]

  @property({ type: Object }) value?: any
  @property({ type: Object }) column: ColumnConfig = ZERO_COLUMN
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Number }) rowIndex?: number
  @property({ type: Object }) field?: DataGridField
  @property({ type: Number }) row?: number

  protected _dirtyValue?: any

  render() {
    return this.editorTemplate
  }

  get editor(): HTMLElement | null {
    return this.renderRoot.firstElementChild as HTMLElement
  }

  get inlineEditable() {
    return false
  }

  async firstUpdated() {
    this.renderRoot.addEventListener('change', this._onchange.bind(this))
    this.renderRoot.addEventListener('focusout', this._onfocusout.bind(this))
    this.addEventListener('click', this._onclick.bind(this))
    this.addEventListener('dblclick', this._ondblclick.bind(this))
    this.addEventListener('keydown', this._onkeydown.bind(this))

    /* editor mode 인 경우의 마우스 움직임이, grist-body의 이벤트 처리에 의해서 에디터를 리셋시킬 수 있으므로, 이벤트 전파를 막는다. */
    this.addEventListener('mousedown', (e: Event) => e.stopPropagation())
    this.addEventListener('mousemove', (e: Event) => e.stopPropagation())
    this.addEventListener('mouseup', (e: Event) => e.stopPropagation())

    const { name = '' } = this.column
    const { align, defaultValue } = this.column.record

    if (align) {
      this.style.textAlign = align
      this.style.textAlignLast = align /* for select */
    }

    var currentValue = this.record[name]

    if (typeof currentValue == 'undefined' && defaultValue) {
      currentValue = getDefaultValue(defaultValue, this.record)
    }

    this.value = this._dirtyValue = this.formatForEditor(currentValue)

    // 입력을 위한 키를 누르면서 편집모드가 될때는 누른 키가 처음에 입력되도록, enter 같은 것을 눌러서 편집모드가 되면 현재 값으로 편집모드 전환
    const valueWith = this.inlineEditable && this.field?.valueWith
    this.value = this._dirtyValue = valueWith ? this.formatFromEditor(valueWith) : this.formatForEditor(currentValue)

    requestAnimationFrame(() => {
      this.focus()
      !valueWith && this.select()
    })
  }

  select() {
    const editor = this.editor
    if ((editor as HTMLInputElement)?.select) {
      return (editor as HTMLInputElement).select()
    }
  }

  focus() {
    const editor = this.editor
    if (editor) {
      editor.focus()
    }
  }

  formatForEditor(value: any): any {
    return value == null ? '' : value
  }

  formatFromEditor(value: any): any {
    return value
  }

  _onfocusout() {
    // paste시 field-change는 Body에서 하므로 X
    if (this.field?.isWorking === true) return

    const { name = '' } = this.column
    var currentValue = this.record[name] || ''

    if (this._dirtyValue !== currentValue) {
      this.dispatchEvent(
        new CustomEvent('field-change', {
          bubbles: true,
          composed: true,
          detail: {
            before: currentValue,
            after: this._dirtyValue,
            column: this.column,
            record: this.record,
            row: this.row
          }
        })
      )
    }
  }

  _onchange(e: Event): void {
    e.stopPropagation()

    const value = (e.target as any)?.value

    this._dirtyValue = this.formatFromEditor(value)
  }

  _onkeydown(e: Event): void {}

  _onclick(e: Event): void {
    e.stopPropagation()
  }

  _ondblclick(e: Event): void {
    e.stopPropagation()
  }

  get editorTemplate() {
    return html``
  }
}
