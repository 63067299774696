import { OxGristEditor } from './ox-grist-editor.js'
import { customElement } from 'lit/decorators.js'
import { html } from 'lit'

@customElement('ox-grist-editor-varname')
export class OxGristEditorVarname extends OxGristEditor {
  get inlineEditable() {
    return true
  }

  get editorTemplate() {
    return html`
      <input
        type="text"
        .value=${this.value}
        @input=${this.handleInput}
        pattern="^[A-Za-z_][A-Za-z0-9_]*$"
        title="Variable names must start with a letter or underscore and contain only letters, numbers, or underscores."
      />
    `
  }

  handleInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement
    const regex = /^[A-Za-z_][A-Za-z0-9_]*$/

    // Only allow valid characters as the user types
    if (!regex.test(input.value)) {
      // Keep only valid characters and start with a letter or underscore
      input.value = input.value.replace(/[^A-Za-z0-9_]/g, '').replace(/^[^A-Za-z_]+/, '')
    }

    // Update the component's internal value state
    this.value = input.value
  }
}
