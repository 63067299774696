import '@operato/i18n/ox-i18n.js'
import '@operato/i18n/ox-i18n-selector.js'
import './change-password'
import './delete-user-popup'
import './my-login-history'

import { gql } from 'graphql-tag'
import { css, html, LitElement, nothing } from 'lit'
import { customElement, property, query, state } from 'lit/decorators.js'
import { startRegistration } from '@simplewebauthn/browser'

import { client } from '@operato/graphql'
import { i18next, localize } from '@operato/i18n'
import { notify, openPopup } from '@operato/layout'
import { auth, getLanguages } from '@things-factory/auth-base/dist-client'

const isAvailableWebauthn = 'PublicKeyCredential' in window
@customElement('profile-component')
export class ProfileComponent extends localize(i18next)(LitElement) {
  static styles = [
    css`
      :host {
        display: block;
        background-color: var(--md-sys-color-background);
        padding: 15px 0;
      }
      .wrap {
        max-width: var(--profile-wrap-max-width, 400px);
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      * {
        box-sizing: border-box;
      }

      *:focus {
        outline: none;
      }

      input {
        margin: var(--spacing-small) 0;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 9px;
        border-radius: var(--border-radius);
        font: var(--auth-input-field-font);
        width: var(--auth-input-field-width);
      }
      input:focus {
        border: 1px solid var(--focus-background-color);
      }

      .user {
        background: url(/assets/images/icon-profile.png) center top no-repeat;
        margin: var(--profile-icon-margin);
        padding: 180px 20px 20px 20px;
        color: var(--md-sys-color-secondary);
        font: var(--header-bar-title);
        text-align: center;
      }

      hr {
        width: 100%;
        border: dotted 1px rgba(0, 0, 0, 0.1);
      }

      .wrap * {
        grid-column: span 2;
      }

      label {
        font: bold 14px var(--theme-font);
        color: var(--md-sys-color-primary);
        text-transform: capitalize;
        grid-column: 1;
      }

      .wrap *.inline {
        grid-column: unset;
      }

      ox-i18n-selector {
        --i18n-selector-field-width: var(--auth-input-field-width);
        --i18n-selector-field-margin: var(--change-password-field-margin);
        --i18n-selector-field-padding: var(--spacing-medium);
        --i18n-selector-field-border-radius: var(--border-radius);
        margin: var(--change-password-field-margin);
      }

      md-text-button {
        text-transform: capitalize;
      }

      footer {
        padding: 20px;
        text-align: center;
      }

      footer p {
        font-size: 14px;
        margin-bottom: 5px;
        color: var(--md-sys-color-on-background);
      }

      footer a {
        color: var(--md-sys-color-primary);
        text-decoration: none;
        font-weight: bold;
      }
    `
  ]

  @property({ type: String }) username?: string
  @property({ type: String }) email?: string
  @property({ type: String }) name?: string

  @state() languages: { code: string; display: string }[] = []
  @state() passwordRule: {
    lowerCase?: boolean
    upperCase?: boolean
    digit?: boolean
    specialCharacter?: boolean
    allowRepeat?: boolean
    useTightPattern?: boolean
    useLoosePattern?: boolean
    tightCharacterLength?: number
    looseCharacterLength?: number
  } = {}

  @query('#username') usernameEl!: HTMLInputElement
  @query('#name') nameEl!: HTMLInputElement
  @query('#email') emailEl!: HTMLInputElement
  @query('#locale') localeEl!: HTMLInputElement

  async connectedCallback(): Promise<void> {
    super.connectedCallback()

    const response = await client.query({
      query: gql`
        query {
          passwordRule {
            lowerCase
            upperCase
            digit
            specialCharacter
            allowRepeat
            useTightPattern
            useLoosePattern
            tightCharacterLength
            looseCharacterLength
          }
        }
      `
    })

    this.passwordRule = response.data
  }

  async firstUpdated() {
    auth.on('profile', ({ credential }) => {
      this.setCredential(credential)
    })

    this.setCredential(auth.credential)

    this.languages = await getLanguages()
  }

  setCredential(credential) {
    if (credential) {
      this.username = credential.username
      this.name = credential.name
      this.email = credential.email
    } else {
      this.username = ''
      this.name = ''
      this.email = ''
    }
  }

  render() {
    return html`
      <div class="wrap">
        <div class="user">${this.username || ''}</div>

        <label for="username"><ox-i18n slot="title" msgid="label.user-id"></ox-i18n></label>
        <input id="username" @change=${e => this.onUsernameChanged(e.target.value)} .value=${this.username || ''} />

        <hr />

        <label for="name"><ox-i18n slot="title" msgid="label.name"></ox-i18n></label>
        <input id="name" @change=${e => this.onNameChanged(e.target.value)} .value=${this.name || ''} />

        <hr />

        <label for="email"><ox-i18n slot="title" msgid="label.email"></ox-i18n></label>
        <input id="email" type="email" @change=${e => this.onEmailChanged(e.target.value)} .value=${this.email || ''} />

        <hr />

        <label for="locale"><ox-i18n slot="title" msgid="label.language"></ox-i18n></label>
        <ox-i18n-selector
          id="locale"
          @change=${e => this.onLocaleChanged(e.detail)}
          value=${i18next.language || 'en-US'}
          .languages=${this.languages}
        ></ox-i18n-selector>

        <hr />

        <label for="change-password">
          <ox-i18n msgid="label.password"></ox-i18n>
        </label>

        <change-password id="change-password" .passwordRule=${this.passwordRule}></change-password>

        ${isAvailableWebauthn
          ? html`
              <md-text-button @click=${() => this.registerUser()}
                >${i18next.t('button.security-key registration')}</md-text-button
              >
            `
          : nothing}

        <footer>
          <p>
            ${i18next.t('text.click login history')}
            <a href="javascript:void(0);" @click=${this.openLoginHistory.bind(this)}
              >${i18next.t('label.login_history')}</a
            >
          </p>
        </footer>
      </div>
    `
  }

  async onUsernameChanged(username) {
    if (!username) return

    var oldUsername = this.username

    try {
      const message = await auth.updateProfile({
        username
      })

      notify({
        level: 'info',
        message
      })
    } catch (e: any) {
      this.usernameEl.value = oldUsername || ''

      notify({
        level: 'error',
        message: 'message' in e ? e.message : e
      })
    }
  }

  async onNameChanged(name) {
    if (!name) return

    var oldName = this.name

    try {
      const message = await auth.updateProfile({
        name
      })

      notify({
        level: 'info',
        message
      })
    } catch (e: any) {
      this.nameEl.value = oldName || ''

      notify({
        level: 'error',
        message: 'message' in e ? e.message : e
      })
    }
  }

  async onEmailChanged(email) {
    if (!email) return

    var oldEmail = this.email

    try {
      const message = await auth.updateProfile({
        email
      })

      notify({
        level: 'info',
        message
      })
    } catch (e: any) {
      this.emailEl.value = oldEmail || ''

      notify({
        level: 'error',
        message: 'message' in e ? e.message : e
      })
    }
  }

  async onLocaleChanged(value) {
    if (!value) return

    var oldLocale = i18next.language

    try {
      const message = await auth.updateProfile({
        locale: value
      })

      i18next.changeLanguage(value)

      notify({
        level: 'info',
        message
      })
    } catch (e: any) {
      this.localeEl.value = oldLocale

      notify({
        level: 'error',
        message: 'message' in e ? e.message : e
      })
    }
  }

  openLoginHistory() {
    openPopup(html` <my-login-history></my-login-history> `, {
      title: i18next.t('label.login_history')
    })
  }

  deleteUser() {
    openPopup(html` <delete-user-popup></delete-user-popup> `, {
      title: i18next.t('label.delete account')
    })
  }

  async registerUser() {
    try {
      const options = await fetch('/auth/register-webauthn/challenge').then(res => res.json())
      const attResp = await startRegistration(options)
      const verification = await fetch('/auth/verify-registration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(attResp)
      }).then(res => res.json())

      if (verification.verified) {
        notify({
          level: 'info',
          message: i18next.t('text.user credential registered successfully')
        })
      } else {
        console.error(await verification.text())

        notify({
          level: 'error',
          message: i18next.t('error.user credential registeration not allowed')
        })
      }
    } catch (error) {
      notify({
        level: 'error',
        message: i18next.t('error.user credential registeration failed')
      })
    }
  }

  get applicationMeta() {
    var iconLink: HTMLLinkElement | null = document.querySelector('link[rel="application-icon"]')
    var titleMeta: HTMLMetaElement | null = document.querySelector('meta[name="application-name"]')
    var descriptionMeta: HTMLMetaElement | null = document.querySelector('meta[name="application-description"]')

    return {
      icon: iconLink?.href || '',
      title: titleMeta?.content || 'Things Factory',
      description: descriptionMeta?.content || 'Reimagining Software'
    }
  }
}
