import moment from '@operato/moment-timezone-es'

type FormatParameters = {
  timeZone?: string
  locale?: string
  format?: any
}

export function formatter(targetTime: number | Date, params: FormatParameters = {}) {
  const { timeZone, format, locale } = params

  if (format === 'date') {
    return new Date(targetTime)
  } else if (format === 'timestamp') {
    return targetTime
  } else if (typeof format == 'string') {
    const t = moment(targetTime)
    if (timeZone) {
      t.tz(timeZone)
    }
    return t.format(format)
  } else if (format || locale) {
    const formatter = new Intl.DateTimeFormat(locale, format)
    return formatter.format(new Date(targetTime))
  } else {
    return targetTime
  }
}
