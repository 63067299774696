import '@material/web/icon/icon.js'
import './data-card-field'
import '../record-view'
import './data-card-gutter'
import './data-card-gutter-menu'

import { GristConfig, GristData, GristRecord } from '../types'
import { LitElement, PropertyValues, css, html } from 'lit'
import { ZERO_CONFIG, ZERO_DATA, ZERO_RECORD } from '../configure/zero-config'
import { customElement, property } from 'lit/decorators.js'

import { RecordViewHandler } from '../record-view/record-view-handler'
import { recordCardClickHandler } from './event-handlers/record-card-click-handler'
import { recordCardDblClickHandler } from './event-handlers/record-card-dblclick-handler'

// TODO 로케일 설정에 따라서 포맷이 바뀌도록 해야한다.
const OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false
  // timeZone: 'America/Los_Angeles'
}

const formatter = new Intl.DateTimeFormat(navigator.language, OPTIONS)

@customElement('ox-record-card')
export class RecordCard extends LitElement {
  static styles = [
    css`
      :host {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        background-color: var(--data-card-record-card-background-color);
        border-radius: var(--data-card-record-card-border-radius);
        border: var(--data-card-record-card-border);
        height: min-content;
        padding: var(--spacing-small) var(--spacing-none);
      }

      :host(:hover) {
        background-color: var(--md-sys-color-surface);
        border: var(--data-card-record-card-border-hover);
        box-shadow: var(--data-card-record-card-boxshadow-hover);
      }

      :host([dirty])::before {
        content: '';
        position: absolute;
        left: var(--spacing-none);
        top: var(--spacing-none);

        width: var(--spacing-none);
        height: var(--spacing-none);
        border-top: var(--grid-record-dirty-border-top);
        border-right: var(--grid-record-dirty-border-left);
      }

      :host[emphasized-row] {
        background-color: var(--grid-record-emphasized-background-color);
        color: var(--grid-record-emphasized-color);
      }

      [dirty] {
        position: absolute;
        margin: var(--spacing-none);
        height: 20px;

        font: var(--grid-record-dirty-icon-font);
        text-indent: 1px;
        left: var(--spacing-none);
        top: var(--spacing-none);
        color: var(--grid-record-dirty-color, var(--md-sys-color-error));
      }

      [thumbnail] {
        height: var(--data-card-thumbnail-height);
        border-top-left-radius: var(--data-card-record-card-border-radius);
        border-top-right-radius: var(--data-card-record-card-border-radius);
        overflow: hidden;
      }

      [content] {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-tiny);
        margin: var(--data-card-item-margin);
      }

      [brief] {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-tiny);
        overflow: hidden;
      }

      [gutters] {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-small);
        margin: var(--spacing-small) var(--spacing-medium);
        justify-content: end;
        align-items: stretch;
      }

      ox-card-field {
        font: var(--data-card-item-etc-font);
        color: var(--data-card-item-etc-color, var(--md-sys-color-on-surface));
      }

      ox-card-field[name] {
        font: var(--data-card-item-name-font);
        color: var(--data-card-item-name-color, var(--md-sys-color-secondary));
      }

      ox-card-field[desc] {
        font: var(--data-card-item-disc-font);
        color: var(--data-card-item-disc-color, var(--md-sys-color-tertiary));
      }
    `
  ]

  @property({ type: Object }) config: GristConfig = ZERO_CONFIG
  @property({ type: Object }) data: GristData = ZERO_DATA
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Number }) rowIndex: number = -1
  /*
   * row-selector를 사용자가 변경할 때, record-card의 update를 유도하기 위해 selected-row attribute를 property에 추가함.
   * (이를 해주지 않으면, 리스트 refresh 경우에 selected-row checkbox가 클리어되지 않는 현상이 발생함.)
   */
  @property({ type: Boolean, attribute: 'selected-row' }) selectedRow: boolean = false
  @property({ attribute: false }) emphasized: any = false

  private _recordView: any
  private clickHandler = recordCardClickHandler.bind(this) as EventListener
  private dblclickHandler = recordCardDblClickHandler.bind(this) as EventListener

  firstUpdated() {
    /*
      long-press
      TODO. performance를 확인한 후에 활성화하자.
    */
    // longpressable(this.renderRoot.querySelector('[content]'))
    // this.renderRoot.addEventListener('long-press', recordPartialLongPressHandler.bind(this))
    this.renderRoot.addEventListener('click', this.clickHandler)
    this.renderRoot.addEventListener('dblclick', this.dblclickHandler)

    this.addEventListener('show-record-view', () => this.popupRecordView())
  }

  updated(changes: PropertyValues<this>) {
    if (changes.has('record') && this._recordView) {
      this._recordView.record = this.record
    }

    const emphasized = this.emphasized

    if (!!emphasized) {
      this.setAttribute('emphasized-row', '')

      if (Symbol.iterator in Object(emphasized)) {
        const [backgroundColor, foregroundColor] = emphasized as string[]
        backgroundColor && this.style.setProperty('--grid-record-emphasized-background-color', backgroundColor)
        foregroundColor && this.style.setProperty('--grid-record-emphasized-color', foregroundColor)
      }
    } else {
      this.removeAttribute('emphasized-row')
    }
  }

  render() {
    const record = this.record
    const rowIndex = this.rowIndex
    const { columns, list } = this.config
    const { thumbnail, fields, details } = list || {}

    const briefFields = fields.map(field => columns.find(column => column.name == field)).filter(column => column) || []
    const detailFields =
      details.map(field => columns.find(column => column.name == field)).filter(column => column) || []

    const thumbnailColumn = thumbnail ? columns.find(column => column.name == thumbnail) : undefined

    const gutters = (columns || []).filter(column => column.type == 'gutter' && column.forList)

    if (this.hasAttribute('dirty')) {
      var dirtyIcon

      switch (this.record['__dirty__']) {
        case 'M':
          dirtyIcon = 'done'
          break
        case '+':
          dirtyIcon = 'add'
          break
        case '-':
          dirtyIcon = 'remove'
          break
      }
    }

    return html`
      ${dirtyIcon ? html` <md-icon dirty>${dirtyIcon}</md-icon> ` : html``}
      ${thumbnailColumn
        ? html` <ox-card-field
            thumbnail
            .rowIndex=${rowIndex}
            .column=${thumbnailColumn}
            .record=${record}
            .value=${record[thumbnailColumn?.name || '']}
          ></ox-card-field>`
        : html``}

      <div content>
        <div brief>
          ${briefFields.map(
            (column, idx) => html`
              <ox-card-field
                .rowIndex=${rowIndex}
                .column=${column}
                .record=${record}
                .value=${record[column?.name || '']}
                ?name=${idx == 0}
                ?desc=${idx == 1}
              ></ox-card-field>
            `
          )}
        </div>
        ${detailFields.length > 0
          ? html`
              <div detail>
                ${detailFields.map(
                  (column, idx) => html`
                    <ox-card-field
                      .rowIndex=${rowIndex}
                      .column=${column}
                      .record=${record}
                      .value=${record[column?.name || '']}
                    ></ox-card-field>
                  `
                )}
              </div>
            `
          : html``}
      </div>

      <div gutters>
        ${gutters.map(gutter => {
          return html`
            <ox-card-gutter
              .rowIndex=${rowIndex}
              .column=${gutter}
              .record=${record}
              .value=${record[gutter.name]}
            ></ox-card-gutter>
          `
        })}
      </div>
    `
  }

  popupRecordView() {
    var titleField = this.config.list.fields[0] || 'name'
    var title = this.record[titleField]

    /* field가 오브젝트형인 경우에는 렌더러를 타이틀로 사용한다. */
    if (typeof title == 'object') {
      var column = this.config.columns.find(column => column.name == titleField)
      title = column?.record.renderer(title, column, this.record, this.rowIndex, this /* cautious */)
    }

    this._recordView = RecordViewHandler(
      this.config.columns,
      this.record,
      this.rowIndex,
      this,
      {
        title
      },
      () => {
        delete this._recordView
      }
    )
  }
}
