import '@material/web/icon/icon.js'
import './record-view-body'
import '@operato/input/ox-input-file.js'
import '../data-grid/data-grid-field'

import { css, html, LitElement } from 'lit'
import { customElement, property } from 'lit/decorators.js'

import { ScrollbarStyles } from '@operato/styles'

import { ZERO_RECORD } from '../configure/zero-config'
import { ColumnConfig, GristRecord } from '../types'

@customElement('ox-record-view')
export class RecordView extends LitElement {
  static styles = [
    ScrollbarStyles,
    css`
      :host {
        display: flex;
        flex-direction: column;
        background-color: var(--record-view-background-color);
      }

      ox-record-view-body {
        flex: 1;
        overflow-y: auto;
      }

      [footer] {
        text-align: right;
        background-color: var(--record-view-footer-background);
        box-shadow: var(--context-toolbar-shadow-line);
      }

      [footer] button {
        display: inline-block;
        align-items: center;
        justify-content: center;

        background-color: transparent;
        border: var(--record-view-footer-button-border);
        border-width: var(--record-view-footer-button-border-width);
        padding: var(--spacing-tiny) var(--spacing-medium);
        color: var(--record-view-footer-button-color);
        font-size: var(--md-sys-typescale-title-medium-size, 1rem);
        line-height: 2;
      }

      [footer] button * {
        vertical-align: middle;
      }

      [footer] button md-icon {
        --md-icon-size: var(--icon-size-small);
        margin-right: var(--spacing-small, 4px);
      }

      [footer] button[ok] {
        background-color: var(--record-view-footer-focus-background);
      }
    `
  ]

  @property({ type: Array }) columns: ColumnConfig[] = []
  @property({ type: Object }) record: GristRecord = ZERO_RECORD
  @property({ type: Number }) rowIndex: number = -1

  render() {
    return html`
      <ox-record-view-body .columns=${this.columns} .record=${this.record} .rowIndex=${this.rowIndex}>
      </ox-record-view-body>
      <div footer>
        <button @click=${this.onReset.bind(this)}><md-icon>refresh</md-icon><span>Reset</span></button>
        <button @click=${this.onCancel.bind(this)}><md-icon>clear</md-icon><span>Cancel</span></button>
        <button @click=${this.onOK.bind(this)} ok><md-icon>task_alt</md-icon><span>OK</span></button>
      </div>
    `
  }

  firstUpdated() {
    this.setAttribute('tabindex', '0')
  }

  onReset() {
    this.focus()

    this.dispatchEvent(
      new CustomEvent('reset', {
        detail: this.record
      })
    )
  }

  onCancel() {
    this.focus()

    this.dispatchEvent(
      new CustomEvent('cancel', {
        detail: this.record
      })
    )
  }

  onOK() {
    this.focus()

    this.dispatchEvent(
      new CustomEvent('ok', {
        detail: this.record
      })
    )
  }
}
