import '@material/web/icon/icon.js'
import './record-view'

import { html, LitElement } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'

import { OxPopup } from '@operato/popup'

import { DataGrist } from '../data-grist'
import { ColumnConfig, GristRecord } from '../types'
import { RecordView } from './record-view'

@customElement('ox-record-creator')
export class RecordCreator extends LitElement {
  @state() grist?: DataGrist

  @property({ type: Object }) callback?: (operation: { [key: string]: any }) => boolean
  @property({ type: Boolean, attribute: 'light-popup' }) lightPopup: boolean = false
  @property({ type: Boolean, attribute: 'prevent-close-on-blur' }) preventCloseOnBlur = false

  constructor() {
    super()

    this.addEventListener('click', (e: Event) => {
      e.preventDefault()
      e.stopPropagation()

      if (this.lightPopup) {
        this.lightPopupRecordView()
      } else {
        this.popupRecordView()
      }
    })
  }

  connectedCallback(): void {
    super.connectedCallback()

    this.grist = this.closest('ox-grist') as DataGrist
  }

  render() {
    return html`<slot></slot>`
  }

  lightPopupRecordView() {
    const config = this.grist!.compiledConfig
    var title = 'create'
    const rowIndex = -1
    var record: GristRecord = {}
    const columns = config.columns

    var popup = OxPopup.open({
      template: html`
        <div title>${title}</div>
        <ox-record-view
          @field-change=${(e: CustomEvent) => {
            const view = e.currentTarget as RecordView

            var { after, before, column, record, row } = (e as CustomEvent).detail as {
              after: any
              before: any
              column: ColumnConfig
              record: GristRecord
              row: number
            }

            var validation = column.validation
            if (validation && typeof validation == 'function') {
              if (!validation.call(this, after, before, record, column)) {
                return
              }
            }

            view.record = {
              ...record,
              [column.name]: after
            }
          }}
          .columns=${columns}
          .record=${record}
          .rowIndex=${rowIndex}
          @reset=${(e: Event) => {
            const view = e.currentTarget as RecordView
            view.record = {}
          }}
          @cancel=${(e: Event) => {
            popup.close()
          }}
          @ok=${(e: Event) => {
            popup.close()

            const view = e.currentTarget as RecordView

            this.dispatchEvent(
              new CustomEvent('ok', {
                bubbles: true,
                composed: true,
                detail: view.record
              })
            )
          }}
        ></ox-record-view>
      `,
      parent: document.body,
      preventCloseOnBlur: this.preventCloseOnBlur
    })
  }

  popupRecordView() {
    const config = this.grist!.compiledConfig
    const rowIndex = -1
    var record: GristRecord = {}
    const columns = config.columns

    var title = 'create'

    var recordView = document.createElement('ox-record-view') as RecordView

    recordView.columns = columns
    recordView.record = record
    recordView.rowIndex = rowIndex

    document.dispatchEvent(
      new CustomEvent('open-popup', {
        detail: {
          template: recordView,
          options: {
            backdrop: true,
            size: 'large',
            title
          },
          callback: (popup: any) => {
            recordView.addEventListener('reset', (e: Event) => {
              const view = e.currentTarget as RecordView
              view.record = {}
            })

            recordView.addEventListener('cancel', (e: Event) => {
              popup.close()
            })

            recordView.addEventListener('ok', async (e: Event) => {
              const view = e.currentTarget as RecordView
              if (await this.callback?.(view.record)) {
                popup.close()
              }
            })

            recordView.addEventListener('field-change', async (e: Event) => {
              const view = e.currentTarget as RecordView

              var { after, before, column, record, row } = (e as CustomEvent).detail as {
                after: any
                before: any
                column: ColumnConfig
                record: GristRecord
                row: number
              }

              var validation = column.validation
              if (validation && typeof validation == 'function') {
                if (!(await validation.call(this, after, before, record, column))) {
                  return
                }
              }

              view.record = {
                ...record,
                [column.name]: after
              }
            })

            popup.onclosed = () => {}
          }
        }
      })
    )
  }
}
