import '@material/web/button/elevated-button.js'
import '@material/web/button/text-button.js'

import '@operato/i18n/ox-i18n.js'
import '../../components/profile-component'

import { html } from 'lit'
import { customElement, query } from 'lit/decorators.js'

import { i18next } from '@operato/i18n'

import { AbstractAuthPage } from '../../components/abstract-auth-page'

@customElement('forgot-password')
export class ForgotPassword extends AbstractAuthPage {
  @query('#email') emailInput!: HTMLInputElement
  @query('#submit-button') button!: HTMLInputElement

  get pageName() {
    return 'forgot password'
  }

  get actionUrl() {
    return '/auth/forgot-password'
  }

  get formfields() {
    const email = this.data?.email || ''

    return html`
      <div class="field">
        <md-filled-text-field
          name="email"
          type="email"
          label=${String(i18next.t('label.email'))}
          .value=${email}
          required
          @input=${(e: Event) => {
            const target = e.target as HTMLInputElement
            if (target.validity.typeMismatch) {
              target.setCustomValidity(i18next.t('text.invalid-email'))
            } else {
              target.setCustomValidity('')
            }
          }}
          ><md-icon slot="leading-icon">mail</md-icon></md-filled-text-field
        >
      </div>
      <md-elevated-button id="submit-button" class="ui button" type="submit" @click=${e => this._onSubmit(e)}>
        <ox-i18n msgid="button.submit"></ox-i18n>
      </md-elevated-button>
    `
  }

  get links() {
    return html`
      <a class="link" href="/auth/signin">
        <md-text-button><ox-i18n msgid="field.sign in"></ox-i18n></md-text-button>
      </a>
    `
  }

  async submit() {
    var timer
    var formData = new FormData(this.formEl)

    try {
      var controller = new AbortController()
      var signal = controller.signal

      this.button.disabled = true
      timer = setTimeout(() => {
        controller.abort()
        throw new Error('timeout')
      }, 30 * 1000)

      var response = await fetch('/auth/forgot-password', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(Object.fromEntries(formData.entries())),
        signal
      })

      this.showSnackbar({
        message: await response.text(),
        level: response.ok ? 'info' : 'error'
      })
    } catch (e) {
    } finally {
      this.button.disabled = false
      clearTimeout(timer)
    }
  }

  showSnackbar({ message, level }) {
    super.showSnackbar({
      level,
      message
    })
  }
}
